/* import the necessary Bootstrap files */
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";

$primary: #00a3f4;
$theme-colors: (
  primary: $primary
);

/* finally, import Bootstrap */
@import "node_modules/bootstrap/scss/bootstrap";
